import React from 'react';
import LoginForm from '../../Components/Form/Login.form';
//Assets
import logoG from '../../Assets/img/Group.png';
import { useAuth } from '../../Hooks/Auth.hook';

const LoginScreen = () => {
  const [{ loading }, login] = useAuth();
  const onSubmit = async ({ username, password }) => {
    await login(username, password);
  };
  return (
    <div className="login-screen fadeIn">
      <div className="container">
        <div className="intent">
          <div className="content">
            <div className="logo">
              <img src={logoG} alt="printek" />
            </div>
            <div style={{ animation: 'fadeIn 200ms' }}>
              <h3>Bienvenido</h3>
              <h5>Inicio de sesión</h5>
              <LoginForm onSubmit={onSubmit} loading={loading} />
            </div>
          </div>
        </div>
        <div className="cover">
          <div className="logo">
            <img src={logoG} alt="printek" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
