import React from 'react';
import { Layout } from 'antd';
import Header from './Header';

const { Content, Footer } = Layout;

const Page = ({ children, hideHeader = false, hideFooter = false }) => {
  return (
    <Layout className="site-layout">
      {!hideHeader && <Header />}
      <Content>{children}</Content>
      {!hideFooter && (
        <Footer style={{ textAlign: 'center' }}>
          Printek® ©2020 Creado por HybridoStudio
        </Footer>
      )}
    </Layout>
  );
};

export default Page;
