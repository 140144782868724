import React from 'react';
import { Menu, } from 'antd';
import sblogo from '../../Assets/img/Group.png';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
import { Routes } from '../../Share/Routes';

const MenuDashboard = () => {
  const [location, navigate] = useNavigation();
  const _parseLocation = () => {
    let replaces = location.pathname
      .replace('/dashboard', '')
      .replace('/dashboard/', '');
    return replaces === '' ? '/' : replaces;
  };
  const _renderRoutes = () => {
    const routes = Routes || [];
    return routes.map(({ title, childs, slug, path, icon }) => {
      return childs.length === 0 ? (
        <Menu.Item
          key={path}
          onClick={() => navigate(`/dashboard${path}`)}
        >
          {title}
        </Menu.Item>
      ) : (
        <Menu.SubMenu title={title} key={slug} icon={icon}>
          {childs.map((data) => {
            return (
              <Menu.Item
                key={path + data.path}
                onClick={() => navigate(`/dashboard${path}${data.path}`)}
              >
                {data.title}
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    });
  };
  return (
    <>
      <div className="logo">
        <img src={sblogo} alt="sharabiz" />
      </div>
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={[_parseLocation()]}
        style={{ height: 'auto', borderRight: 0, textAlign: 'left' }}
      >
        {_renderRoutes()}
      </Menu>
    </>
  );
};

export default MenuDashboard;
