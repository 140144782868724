import React from 'react';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
//Hooks
import { useFetchOrders } from '../../Hooks/Orders.hook';
import { useNavigation } from '../../Hooks/Nav.hook';
const OrderTable = () => {
  const [data, loading, , params] = useFetchOrders();
  const [, nav] = useNavigation();
  const columns = [
    {
      title: 'Servicio',
      dataIndex: ['service', 'name'],
    },
    {
      title: 'Cliente',
      dataIndex: ['user', 'full_name'],
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'created_at',
      render: (e) => dayjs(e).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      render(e) {
        switch (e) {
          case 1:
            return 'Ingresado';
          case 2:
            return 'Pagado';
          case 3:
            return 'En proceso';
          case 4:
            return 'Completado';
          case 5:
            return 'Enviado';
          case 6:
            return 'Entregado';
          default:
            return 'Cancelado';
        }
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (e) => `$ ${e}`,
    },
    {
      title: 'Pagado',
      dataIndex: 'paid',
      render: (e) => (e ? 'Si' : 'No'),
    },
    {
      title: '',
      dataIndex: '_id',
      render: (e) => (
        <Button type="link" onClick={() => nav(`/dashboard/order?_id=${e}`)}>
          Ver orden
        </Button>
      ),
    },
  ];
  const tableProps = {
    columns,
    loading,
    className: 'responsive-table',
    dataSource: data.data,
    rowKey: (e) => e._id,
    pagination: {
      total: data.total,
      current: data.skip / 10 + 1,
      onChange: (e) => params([], (e - 1) * 10),
    },
    locale: { emptyText: 'Sin registros' },
    onRow: (_, rowIndex) => {
      return {
        className: rowIndex % 2 === 0 ? 'even' : 'non' ,
      };
    },
  };
  return <Table {...tableProps} />;
};
export default OrderTable;
