import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';

import { persistor } from '../Redux/store';

import { Auth } from '../Redux/reducers/auth';

import { login } from '../Services/API';
import { App } from '../Redux/reducers/app';

export function useAuth() {
  const { auth } = useSelector((state) => ({ auth: state.auth }));
  const dispatch = useDispatch();
  async function post(username, password) {
    dispatch(Auth.authRequest());
    let response = await login(username, password);
    if (response.ok) {
      let { accessToken, user } = response.data;
      dispatch(
        Auth.authSuccess(accessToken, user)
      );
    } else {
      dispatch(Auth.authFailure());
      message.error('Error de autenticación');
    }
  }
  function logout() {
    dispatch(Auth.authClear());
    dispatch(App.appClear());
    persistor.flush();
    persistor.purge();
  }
  return [auth, post, logout];
}
