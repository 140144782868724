import React from 'react';
import { Home, Folder } from 'react-feather';


export const Routes = [
  {
    title: 'Inicio',
    slug: 'home',
    path: '/',
    icon: <Home size={16} strokeWidth={3} className="anticon" />,
    childs: [],
  },
  {
    title: 'Archivos',
    slug: 'files',
    path: '/files',
    icon: <Folder size={16} strokeWidth={3} className="anticon" />,
    childs: [],
  },
];
