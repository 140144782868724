import React from 'react';
import { Result, Button } from 'antd';
import Page from '../../Components/Layout/Page';

const NotFound = () => {
  return (
    <Page>
      <Result
        className="slideInLeft"
        status="404"
        title="404"
        subTitle={'Esta página no existe'}
        extra={
          <Button type="link" href="/">
            Ir a inicio
          </Button>
        }
      />
    </Page>
  );
};

export default NotFound;
