import React from 'react';
import { Card, Divider } from 'antd';
import Page from '../../Components/Layout/Page';
import OrderTable from '../../Components/Tables/OrderTable';

const HomeScreen = () => {
  return (
    <Page >
      <div className="screen-layout slideInLeft">
        <Card className="container">
          <Divider orientation="left">Ordenes</Divider>
          <OrderTable/>
        </Card>
      </div>
    </Page>
  );
};
export default HomeScreen;
