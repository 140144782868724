import React from 'react';

const Preloader = () => {
  return (
    <div className="preloader fadeIn">
      <div className="loading">
        <p>Cargando</p>
        <span></span>
      </div>
    </div>
  );
};

export default Preloader;
