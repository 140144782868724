import immutable from 'seamless-immutable';
import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  appDrawer: ['drawer'],
  appClear: [],
  appReady: [],
  appMenus: ['menus'],
});
const INITIAL_STATE = immutable({
  drawer: false,
  error: false,
  errorMessage: null,
  ready: false,
  menus: {
    fetch: false,
    items: [],
  },
});

function drawer(state, action) {
  return state.merge({
    drawer: action.drawer,
  });
}
function clear(state, action) {
  return INITIAL_STATE;
}
function ready(state, action) {
  return state.merge({ ready: true });
}
function setMenu(state, action) {
  const { menus } = action;
  return state.merge({
    menus: {
      items: menus,
      fetch: true,
    },
  });
}
const HANDLERS = {
  [Types.APP_CLEAR]: clear,
  [Types.APP_DRAWER]: drawer,
  [Types.APP_MENUS]: setMenu,
  [Types.APP_READY]: ready,
};

export const App = Creators;
export const appTypes = Types;
export default createReducer(INITIAL_STATE, HANDLERS);
