import { useEffect, useState } from 'react';
import { getPath, getCustomPath } from '../Services/API';

export const useFetchUserFiles = (user_id, mode = 'public') => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ Contents: [] });
  useEffect(() => {
    setLoading(true);
    if (mode === 'private') {
      getPath().then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getPath({ is_public: true }).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }, [user_id, setData, setError, setLoading, mode]);
  async function update() {
    setLoading(true);
    if (mode === 'private') {
      getPath().then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getPath({ is_public: true }).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }
  return [data, loading, error, update];
};

export const useFetchUserFilesPath = (mode = 'public', user_id, path) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ Contents: [], CommonPrefixes: [] });
  const parse = path.replace(user_id, '');
  useEffect(() => {
    setLoading(true);
    if (mode === 'private') {
      getCustomPath(path).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getCustomPath(path, { is_public: true }).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }, [setData, setError, setLoading, path, parse, mode]);
  async function update() {
    setLoading(true);
    if (mode === 'private') {
      getCustomPath(path).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    } else {
      getCustomPath(path, { is_public: true }).then((response) => {
        if (response.ok) {
          setData(response.data);
        } else {
          setError(response.data);
        }
        setLoading(false);
      });
    }
  }
  return [data, loading, error, update, parse, mode];
};
