import { useEffect, useState } from 'react';
import { getOrders, getOrder } from '../Services/API';
//Labels
export function useFetchOrders(queries = [], skip = 0, limit = 10) {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ queries, skip, limit });
  const [error, setError] = useState(null);
  const [data, setData] = useState({ data: [], total: 0, skip: 0 });
  useEffect(() => {
    setLoading(true);
    getOrders(params.queries, params.skip, params.limit).then((response) => {
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.data);
      }
      setLoading(false);
    });
  }, [params, setData, setError, setLoading]);
  function onChangeParams(q = [], s = 0, l = 10) {
    setParams({ queries: q, skip: s, limit: l });
  }
  async function update() {
    setLoading(true);
    let response = await getOrders(params.queries, params.skip, params.limit);
    if (response.ok) {
      setData(response.data);
    } else {
      setError(response.data);
    }
    setLoading(false);
  }
  return [data, loading, error, onChangeParams, update];
}
export function useFetchOrder(order_id) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ data: [], total: 0, skip: 0 });
  useEffect(() => {
    setLoading(true);
    getOrder(order_id).then((response) => {
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.data);
      }
      setLoading(false);
    });
  }, [setData, setError, setLoading, order_id]);
  async function update(obj) {
    if (obj._id) {
      setData(obj);
    } else {
      setLoading(true);
      let response = await getOrder(order_id);
      if (response.ok) {
        setData(response.data);
      } else {
        setError(response.data);
      }
      setLoading(false);
    }
  }
  return [data, loading, error, update];
}
