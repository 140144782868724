import React from 'react';
import { Layout, Avatar, Row, Col, Popover } from 'antd';
import { BarChart, LogOut } from 'react-feather';

//Hooks
import { useMobile } from '../../Hooks/Responsive.hook';
import { useAuth } from '../../Hooks/Auth.hook';
import { useDrawer } from '../../Hooks/App.hook';
import MenuDashboard from './MenuDashboard';

const Header = () => {
  const [mobile] = useMobile();
  const [, drawer] = useDrawer();
  const [{ user }, , logout] = useAuth();
  const content = (
    <div>
      <div className="pointer menu-item" onClick={logout}>
        <LogOut
          className="anticon"
          style={{ marginRight: 5, width: 16, height: 16 }}
        />
        <span className="label">Cerrar sesión</span>
      </div>
    </div>
  );
  function _clipName(name = '') {
    let words = name.split(' '),
      clip = '';
    for (let word of words) {
      clip += word[0];
    }
    return clip.toLocaleUpperCase();
  }
  return (
    <Layout.Header className="header site-layout-background">
      <div className="left-flex ">
        {mobile ? (
          <span className="trigger">
            <BarChart
              size={25}
              strokeWidth={1}
              className="menu-bar"
              onClick={() => drawer(true)}
            />
          </span>
        ) : (
          <MenuDashboard />
        )}
        <div className="logo"></div>
      </div>
      {!mobile ? (
        <Row gutter={[16, 16]} style={{ width: '450px' }}>
          <Col span={6} className="user-avatar"></Col>
          <Col span={12}>
            <div className="head-title">
              <div className="title">{user.full_name}</div>
              <div className="sub">{user.email}</div>
            </div>
          </Col>
          <Col span={6} className="user-avatar">
            <Popover content={content} trigger="click" placement="bottom">
              <Avatar
                size="default"
                style={{ background: '#A787FF' }}
                className="pointer"
              >
                {_clipName(user.full_name || user.name)}
              </Avatar>
            </Popover>
          </Col>
        </Row>
      ) : (
        <div className="pointer" style={{ paddingTop: 7, textAlign: 'right' }}>
          <LogOut
            className="anticon"
            style={{ color: '#00000015' }}
            onClick={logout}
          />
        </div>
      )}
    </Layout.Header>
  );
};

export default Header;
