import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const LoginForm = ({ onSubmit, loading = false }) => {
  /**
   * Send action with input values
   * @param {Object} values
   */
  const onFinish = async (values) => {
    if (typeof onSubmit === 'function') {
      await onSubmit(values);
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Ingrese su usuario' }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          className="sub-input"
          placeholder="Usuario"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Ingrese su contraseña' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          className="sub-input"
          placeholder="Contraseña"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button form-button-dark"
          loading={loading}
        >
          Entrar
        </Button>
      </Form.Item>
    </Form>
  );
};
LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default LoginForm;
