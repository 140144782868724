import React, { useEffect } from 'react';
import { Layout } from 'antd';
import SubRouter from '../../Components/SubRouter';


import { useMobile } from '../../Hooks/Responsive.hook';
import MenuMobile from '../../Components/Layout/MenuMobile';
import Preloader from '../../Components/Layout/Preloader';
import { useMount } from '../../Hooks/App.hook';

const DashboardScreen = () => {
  const [mobile] = useMobile();
  const [mount, change] = useMount();
  useEffect(() => {
    if (!mount) {
      setTimeout(() => {
        change(true);
      }, 3000);
    }
  });
  if (!mount) {
    return <Preloader />;
  }
  return (
    <Layout className="dashboard-screen fadeIn">
      {mobile && <MenuMobile />}
      <SubRouter />
    </Layout>
  );
};

export default DashboardScreen;
