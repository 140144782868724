import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import HomeScreen from '../Screens/Home';
import FileScreen from '../Screens/Files';
import NotFound from '../Screens/NotFound';
import OrderDetailScreen from '../Screens/OrderDetail';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        path={`${url}`}
        component={HomeScreen}
        location={locations}
        exact
      />
      <Route
        path={`${url}/order`}
        component={OrderDetailScreen}
        location={locations}
      />
      <Route
        path={`${url}/files`}
        component={FileScreen}
        location={locations}
        exact
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(SubRouter);
