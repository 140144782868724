import { Select } from 'antd';
import React from 'react';

const StatusSelect = (props) => {
  return (
    <Select {...props}>
      <Select.Option value={1}>Ingresado</Select.Option>
      <Select.Option value={2}>Pagado</Select.Option>
      <Select.Option value={3}>En proceso</Select.Option>
      <Select.Option value={4}>Completado</Select.Option>
      <Select.Option value={5}>Enviado</Select.Option>
      <Select.Option value={6}>Entregado</Select.Option>
      <Select.Option value={0}>Cancelado</Select.Option>
    </Select>
  );
};

export default StatusSelect;
