import { create } from 'apisauce';
import { store } from '../Redux/store';
import { setQuery } from '../Utils/query';
import { getServer } from '../Utils/url';

export const token = () => store.getState().auth.token;
// Define the api
const API = create({
  baseURL: getServer(),
});

export async function login(email, password) {
  return await API.post('/auth', { email, password, strategy: 'local' });
}

//Orders
export const getOrders = async (queries = [], skip = 0, limit = 300) =>
  await API.get(
    `/order-headers?$skip=${skip}&$limit=${limit}${setQuery(queries)}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getOrder = async (order) =>
  await API.get(
    `/order-headers/${order}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createOrden = async (body) =>
  await API.post(`/order-headers`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });
export const updateOrden = async (id, body) =>
  await API.patch(`/order-headers/${id}`, body, {
    headers: {
      Authorization: `bearer ${token()}`,
    },
  });

//Multimedia
export const getPath = async () =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getCustomPath = async (custom_path, body = {}) =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path,
      ...body,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const getPathByUser = async (id) =>
  await API.post(
    `/utils`,
    {
      action: 'list-directories',
      custom_path: id + '/',
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const createFolder = async (custom_path, is_public = false) =>
  await API.post(
    `/utils`,
    {
      action: 'create-folder',
      custom_path,
      is_public,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const deleteFolder = async (path) =>
  await API.post(
    `/utils`,
    {
      action: 'remove-folder',
      path,
    },
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );
export const deleteFile = async (id) =>
  await API.delete(
    `/multimedia/${id}`,
    {},
    {
      headers: {
        Authorization: `bearer ${token()}`,
      },
    }
  );

export const downloadFile = async (path, fileName = 'file') => {
  let response = await fetch(
    `${getServer()}/upload?target=${path}&token=${token()}`,
    { method: 'GET' }
  );
  if (response.ok) {
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(await response.blob());
    //Link styles
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    //Click link
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
};
