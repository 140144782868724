import React from 'react';
import { Table } from 'antd';
const OrderDetailTable = ({ data, loading = false }) => {
  const columns = [
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
    },
    {
      title: 'Concepto',
      dataIndex: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      render: (e) => `$ ${e}`,
    },
    {
      title: 'Impuestos',
      dataIndex: 'tax',
      render: (e) => `$ ${e}`,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (e) => `$ ${e}`,
    },
  ];
  const tableProps = {
    columns,
    loading,
    className: 'responsive-table',
    dataSource: data,
    rowKey: (e) => e._id,
    locale: { emptyText: 'Sin registros' },
    size: 5,
    pagination: { pageSize: 5 },
    onRow: (_, rowIndex) => {
      return {
        className: rowIndex % 2 === 0 ? 'even' : 'non' ,
      };
    },
  };
  return <Table {...tableProps} />;
};
export default OrderDetailTable;
