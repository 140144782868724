import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { App } from '../Redux/reducers/app';

export function useDrawer() {
  const drawer = useSelector(({ app }) => app.drawer);
  const dispatch = useDispatch();
  function change(value) {
    dispatch(App.appDrawer(value));
  }
  return [drawer, change];
}

export function useMount() {
  const ready = useSelector(({ app }) => app.ready);
  const dispatch = useDispatch();
  function mount() {
    dispatch(App.appReady());
  }
  return [ready, mount];
}

export const useFullScreen = (ref) => {
  const [active, setActive] = useState(false);
  function open() {
    let doc = document.body,
      requestFullScreen =
        doc.requestFullscreen ||
        doc.mozRequestFullScreen ||
        doc.webkitRequestFullScreen ||
        doc.msRequestFullscreen;
    requestFullScreen();
    setActive(true);
  }
  function exit() {
    let doc = document.body,
      cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;
    cancelFullScreen();
    setActive(false);
  }
  return [active, open, exit];
};
