import React from 'react';
import { Card, Descriptions, message } from 'antd';
import dayjs from 'dayjs';
import Page from '../../Components/Layout/Page';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
import { useFetchOrder } from '../../Hooks/Orders.hook';
import OrderDetailTable from '../../Components/Tables/OrderDetailTable';
import StatusSelect from '../../Components/Captura/StatusSelect';
import { updateOrden } from '../../Services/API';

const OrderDetailScreen = () => {
  const [
    {
      query: { _id },
    },
  ] = useNavigation();
  const [order, loading, , update] = useFetchOrder(_id);
  const _handleChangeStatus = async (status) => {
    console.log(status);
    let response = await updateOrden(order._id, { status });
    if (response.ok) {
      message.success('Orden actualizada');
      update(response.data);
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <Page>
      <div className="screen-layout slideInLeft">
        {loading ? (
          'Cargando...'
        ) : (
          <Card className="container fadeIn">
            <Descriptions title="Información General">
              <Descriptions.Item label="Nombre del servicio">
                {order.service.name}
              </Descriptions.Item>
              <Descriptions.Item label="Cliente">
                {order.user.full_name}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha de la orden">
                {dayjs(order.created_at).format('DD/MM/YYYY HH:mm')}
              </Descriptions.Item>
              <Descriptions.Item label="Descripción">
                {order.description}
              </Descriptions.Item>
              <Descriptions.Item label="Estatus">
                <StatusSelect
                  onChange={_handleChangeStatus}
                  value={order.status}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Cantidad">
                {order.quantity}
              </Descriptions.Item>
              <Descriptions.Item label="Precio base">
                $ {order.base_price}
              </Descriptions.Item>
              <Descriptions.Item label="Subtotal">
                $ {order.subtotal}
              </Descriptions.Item>
              <Descriptions.Item label="Impuestos">
                $ {order.tax}
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                $ {order.total}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Dirección">
              <Descriptions.Item label="País">
                {order.country}
              </Descriptions.Item>
              <Descriptions.Item label="Estado">
                {order.state}
              </Descriptions.Item>
              <Descriptions.Item label="Ciudad">{order.city}</Descriptions.Item>
              <Descriptions.Item label="Dirección">
                {order.address}
              </Descriptions.Item>
              <Descriptions.Item label="Número exterior">
                {order.ex_no}
              </Descriptions.Item>
              <Descriptions.Item label="CP">{order.zip_code}</Descriptions.Item>
            </Descriptions>
            <h2>Detalles</h2>
            <OrderDetailTable data={order.detail} />
          </Card>
        )}
      </div>
    </Page>
  );
};

export default OrderDetailScreen;
