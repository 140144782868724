import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import fileSize from 'filesize';
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Upload as UploadAntd,
  message,
  List,
  Form,
  Breadcrumb,
  Tabs,
} from 'antd';
import immutable from 'seamless-immutable';
import { Upload, Trash, FolderPlus } from 'react-feather';
import { InboxOutlined, HomeOutlined } from '@ant-design/icons';

import { getServer } from '../../Utils/url';

//Hooks
import { useAuth } from '../../Hooks/Auth.hook';
import { useFetchUserFilesPath } from '../../Hooks/Files.hook';
import {
  createFolder,
  deleteFile,
  deleteFolder,
  downloadFile,
} from '../../Services/API';
import FileIcon from '../../Components/Files/FileIcon';
import FolderForm from '../../Components/Form/Folder.form';
import Page from '../../Components/Layout/Page';

const { Dragger } = UploadAntd;

const _getFileType = (name = '') => {
  let names = name.split('.');
  return names[names.length - 1];
};
const Files = () => {
  const [modal, setModal] = useState(false);
  const [folderModal, setFolderModal] = useState(false);
  const [mode, setMode] = useState('public');
  const [fileList, setFileList] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [formRef] = Form.useForm();
  const [auth] = useAuth();
  const [fileManager, setFileManager] = useState(
    immutable({
      parentNode: `mode/users/${auth.user._id}`,
      currentPath: '',
      backPath: [],
    })
  );
  const [files, loading, , update] = useFetchUserFilesPath(
    mode,
    auth.user._id,
    fileManager.currentPath === ''
      ? mode === 'public'
        ? ''
        : `${auth.user._id}/`
      : fileManager.currentPath.replace(
          fileManager.parentNode,
          `${auth.user._id}`
        )
  );
  const [query, setQuery] = useState('');
  const data = {
    custom_path: fileManager.currentPath
      .replace(fileManager.parentNode, '')
      .replace('/', ''),
    ...(mode === 'public' ? { is_public: true } : {}),
  };
  const props = {
    fileList,
    name: 'files',
    multiple: true,
    action: `${getServer()}/multimedia`,
    data:
      fileManager.currentPath === ''
        ? mode === 'public'
          ? { is_public: true }
          : {}
        : data,
    headers: {
      Authorization: `bearer ${auth.token}`,
    },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status === 'done') {
        message.success(
          `Se ha subido el archivo ${info.file.name}  exitosamente.`
        );
        setModal(false);
        setFileList([]);
        update();
      } else if (status === 'error') {
        message.error(`Error subiendo el archivo ${info.file.name}.`);
      }
    },
  };
  useEffect(() => {
    if (mode === 'public') {
      setFileManager(
        immutable({
          parentNode: `prink/public`,
          currentPath: '',
          backPath: [],
        })
      );
    } else {
      setFileManager(
        immutable({
          parentNode: `prink/users/${auth.user._id}`,
          currentPath: '',
          backPath: [],
        })
      );
    }
  }, [mode, setFileManager, auth.user._id]);
  const _confirmDelete = (file_id, type = 'file', event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    Modal.confirm({
      title:
        '¿Estás seguro de que quieres borrar ' +
        (type === 'file' ? 'este archivo?' : 'está carpeta?'),
      content:
        (type === 'file'
          ? 'Los archivos eliminados'
          : 'Las carpetas eliminadas') + ' no se podrán recuperar',
      okText: 'Si',
      cancelText: 'No',
      onOk: async () => {
        if (type === 'file') {
          await deleteFile(file_id).then((response) => {
            if (response.ok) {
              message.success('Archivo eliminado');
              update();
            } else {
              message.error('No se pudo eliminar el archivo');
            }
          });
        } else {
          await deleteFolder(file_id).then((response) => {
            if (response.ok) {
              message.success('Carpeta eliminada');
              update();
            } else {
              message.error('No se pudo eliminar la carpeta');
            }
          });
        }
      },
    });
  };

  const _filterPathFiles = () => {
    let { parentNode, currentPath } = fileManager,
      newArray = [];
    if (currentPath !== '') {
      newArray.push({ type: 'Back' });
    }
    for (let path of files.CommonPrefixes) {
      let preKey = path.Prefix.replace(parentNode, '').split('/');
      newArray.push({
        ...path,
        type: 'Folder',
        RealName: preKey[preKey.length - 2],
      });
    }
    for (let path of files.Contents) {
      if (path.RealName) {
        newArray.push({
          ...path,

          type: 'File',
        });
      }
    }

    return loading ? [] : newArray;
  };

  const _filterFiles = () => {
    if (!query) {
      return _filterPathFiles();
    }
    return _filterPathFiles().filter(
      (filter) =>
        filter.RealName.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1
    );
  };

  const _createFolder = async (values) => {
    let response = await createFolder(
      fileManager.currentPath
        .replace(fileManager.parentNode, '')
        .replace('/', '') +
        values.name +
        '/',
      mode === 'public'
    );
    if (response.ok) {
      update();
      formRef.resetFields();
      setFolderModal(false);
    }
  };

  useEffect(() => {
    let sCurrent = '';
    if (fileManager) {
      if (fileManager.currentPath) {
        if (fileManager.parentNode) {
          sCurrent = fileManager.currentPath.replace(
            fileManager.parentNode,
            ''
          );
        }
      }
    }

    setBreadcrumb(sCurrent.split('/'));
  }, [fileManager]);

  return (
    <Page>
      <div className="screen-layout slideInLeft">
        <Card className="slideInLeft container">
          <Tabs
            defaultActiveKey="public"
            activeKey={mode}
            onChange={(e) => setMode(e)}
          >
            <Tabs.TabPane tab="Archivos Públicos" key="public"></Tabs.TabPane>
            <Tabs.TabPane tab="Mis Archivos" key="private"></Tabs.TabPane>
          </Tabs>
          <Modal
            title="Subir nuevo archivo"
            visible={modal}
            onCancel={() => setModal(false)}
            footer={[]}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click o arrastra el archivo en esta área para subir
              </p>
              <p className="ant-upload-hint">
                Los archivos se subir&aacute;n automaticamente
              </p>
            </Dragger>
          </Modal>
          <Modal
            title="Nueva carpeta"
            visible={folderModal}
            onOk={() => formRef.submit()}
            onCancel={() => {
              setFolderModal(false);
              formRef.resetFields();
            }}
            okText="Crear"
            cancelText="Canelar"
          >
            <FolderForm formRef={formRef} onSubmit={_createFolder} />
          </Modal>
          <div className="content">
            <Row justify="space-between">
              <Col>
                <Button
                  type="primary"
                  icon={
                    <Upload size={14} strokeWidth={2} className="anticon" />
                  }
                  onClick={() => setModal(true)}
                  style={{ marginRight: 8 }}
                >
                  Subir archivo
                </Button>
                <Button
                  onClick={() => setFolderModal(true)}
                  icon={
                    <FolderPlus size={14} strokeWidth={2} className="anticon" />
                  }
                >
                  Crear carpeta
                </Button>
              </Col>
              <Col>
                <Input
                  placeholder="Buscar archivo"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Col>
                <span
                  style={{
                    display: 'inline-block',
                    marginRight: 10,
                  }}
                >
                  Directorio:
                </span>
                {breadcrumb.length && (
                  <Breadcrumb
                    style={{
                      display: 'inline-block',
                    }}
                  >
                    <Breadcrumb.Item>
                      <HomeOutlined />
                    </Breadcrumb.Item>
                    {breadcrumb.map((item) => (
                      <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                )}
              </Col>
            </Row>
            <div className="file">
              <List
                itemLayout="horizontal"
                dataSource={_filterFiles()}
                locale={{ emptyText: 'Sin Archivos' }}
                loading={loading}
                renderItem={(item) => {
                  if (item.type === 'Folder') {
                    return (
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        className="folder-element"
                        actions={[
                          <Button
                            danger
                            type="primary"
                            onClick={(event) =>
                              _confirmDelete(item.Prefix, 'folder', event)
                            }
                            icon={
                              <Trash
                                size={14}
                                strokeWidth={2}
                                className="anticon"
                              />
                            }
                          />,
                        ]}
                        onClick={() =>
                          setFileManager(
                            fileManager.merge({
                              currentPath: item.Prefix,
                              backPath: [
                                ...fileManager.backPath,
                                fileManager.currentPath,
                              ],
                            })
                          )
                        }
                      >
                        <List.Item.Meta
                          avatar={
                            <FileIcon
                              fileType="folder"
                              style={{ fontSize: 30 }}
                              twoToneColor="#60A50D"
                            />
                          }
                          title={item.RealName}
                        />
                      </List.Item>
                    );
                  }
                  if (item.type === 'Back') {
                    return (
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        className="back-element"
                        onClick={() =>
                          setFileManager(
                            fileManager.merge({
                              currentPath:
                                fileManager.backPath[
                                  fileManager.backPath.length - 1
                                ],
                              backPath: fileManager.backPath.slice(
                                0,
                                fileManager.backPath.length - 1
                              ),
                            })
                          )
                        }
                      >
                        <List.Item.Meta
                          avatar={
                            <FileIcon
                              fileType="arrow-l"
                              style={{ color: '#7AB32B' }}
                              size={22}
                              strokeWidth={2}
                            />
                          }
                          title="Regresar"
                        />
                      </List.Item>
                    );
                  }
                  return (
                    <List.Item
                      actions={[
                        <span
                          key="list-loadmore-edit"
                          onClick={() =>
                            downloadFile(
                              item.Key.replace('mlc/', ''),
                              item.RealName
                            )
                          }
                        >
                          Descargar
                        </span>,
                        <Button
                          danger
                          type="primary"
                          onClick={() => _confirmDelete(item._id)}
                          icon={
                            <Trash
                              size={14}
                              strokeWidth={2}
                              className="anticon"
                            />
                          }
                        />,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <FileIcon
                            fileType={_getFileType(item.RealName)}
                            style={{ fontSize: 30 }}
                            twoToneColor="#60A50D"
                          />
                        }
                        title={item.RealName}
                        description={`Fecha de modificación: ${dayjs(
                          files.LastModified
                        ).format('DD/MM/YYYY hh:ss a')} \n Tamaño: ${fileSize(
                          item.Size
                        )}`}
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </Page>
  );
};

export default Files;
