import React from 'react';
import { Menu, Drawer } from 'antd';
import logo from '../../Assets/img/Group.png';
//Hooks
import { useNavigation } from '../../Hooks/Nav.hook';
import { useDrawer } from '../../Hooks/App.hook';

import { Routes } from '../../Share/Routes';

const MenuMobile = () => {
  const [location, navigate] = useNavigation();
  const [visible, onChange] = useDrawer();
  const _parseLocation = () => {
    let replaces = location.pathname
      .replace('/dashboard', '')
      .replace('/dashboard/', '');
    return replaces === '' ? '/' : replaces;
  };
  const _renderRoutes = () => {
    const routes = Routes || [];
    return routes.map(({ title, childs, slug, path, icon }) => {
      return childs.length === 0 ? (
        <Menu.Item
          key={path}
          onClick={() => navigate(`/dashboard${path}`)}
          icon={icon}
        >
          {title}
        </Menu.Item>
      ) : (
        <Menu.SubMenu title={title} key={slug} icon={icon}>
          {childs.map((data) => {
            return (
              <Menu.Item
                key={path + data.path}
                onClick={() => navigate(`/dashboard${path}${data.path}`)}
                icon={icon}
              >
                {data.title}
              </Menu.Item>
            );
          })}
        </Menu.SubMenu>
      );
    });
  };
  return (
    <Drawer
      placement="left"
      closable={false}
      visible={visible}
      onClose={() => onChange(false)}
      bodyStyle={{ padding: 0 }}
    >
      <div className="menu-drawer">
        <img
          src={logo}
          alt="Index"
          style={{
            width: '70px',
            margin: 'auto',
            marginBottom: 50,
            marginTop: 50,
          }}
        />
        <Menu
          mode="inline"
          defaultSelectedKeys={[_parseLocation()]}
          style={{ height: '70%', borderRight: 0, textAlign: 'left' }}
        >
          {_renderRoutes()}
        </Menu>
      </div>
    </Drawer>
  );
};

export default MenuMobile;
